.luckco7-frame-active[data-v-55d85284] {
    bottom: 0
}

.luckco7-frame[data-v-55d85284] {
    width: 100vw;
    height: 71.4666666667vw;
    position: fixed;
    background-color: #000;
    border-top-left-radius: 15px;
    background-position: center;
    /*background-image: url('../../images/Banner/bg-navcasnio.gif');*/
    border-top-right-radius: 15px;
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, .6);
    overflow: hidden;
}
.spaceBgVideo {
    height: auto;
    width: 128%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-filter: blur(3px) grayscale(88%);
    -moz-filter: blur(3px) grayscale(88%);
    -o-filter: blur(3px) grayscale(88%);
    /*-ms-filter: blur(3px) grayscale(88%);*/
    filter: blur(3px) grayscale(88%);
    padding-top: 0 !important;

}

video[data-v-55d85284] {
    -o-object-fit: none;
    object-fit: none;
    overflow: clip
}

.header__number[data-v-55d85284] {
    position: absolute;
    left: 32%;
    color: #e6c500;
    font-size: 18px;
    font-weight: 700
}

.card-wrapper[data-v-55d85284] {
    display: flex;
    margin-left: 4vw;
    overflow: scroll;
    margin-top: 5vw;
}

.game-card[data-v-55d85284] {
    position: relative;
    height: 45vw;
    background-color: #ffe66c;
    margin-right: 16px;
    padding: 4px;
    border-radius: 12px;

}

.game-card .player[data-v-55d85284] {
    position: relative;
    bottom: 13%;
    width: 18vw;
    background: #ffe66c;
    border: 6px solid #5f3b3b;
    border-radius: 30px;
}

.game-card .player__icon[data-v-55d85284] {
    display: inline-flex;
    position: relative;
    left: 4px;
    width: 5vw;
    height: 5vw;
    background: linear-gradient(-180deg, #ffe66c, #ffe66c);
    border-radius: 30px;
}

.game-card .player__number[data-v-55d85284] {
    color: #000;
    position: relative;
    left: 1.8vw;
    font-size: 3.4666666667vw;

    bottom: 0.9vw;
    left: 3vw;
}

.game-card .game-name[data-v-55d85284] {
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 14%
}

.game-card .game-name__text[data-v-55d85284] {
    color: #000;
    font-weight: 800;
    line-height: 9vw
}

#miniGameIframe[data-v-55d85284] {
    width: 100%;
    height: 100%;
    border-radius: 35px 35px 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url(../img/games_lobby-bg.84b4df8f.png); */
    position: absolute
}
