@media (min-device-width: 576px) {
    .main-wrapper{
        transform: scale(0.46) translateY(-122vh) translateX(-59%);
    }
}


.app-wrapper-row {
    display: flex;
    flex-wrap: wrap;
}

.app-wrapper-column{
    flex: 0 0 auto;
    width: 33.33333333%
}
