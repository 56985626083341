/*.text-focus-in {*/
/*    -webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;*/
/*    animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;*/
/*}*/

/*!* ----------------------------------------------*/
/* * Generated by Animista on 2024-2-23 20:22:25*/
/* * Licensed under FreeBSD License.*/
/* * See http://animista.net/license for more info.*/
/* * w: http://animista.net, t: @cssanimista*/
/* * ---------------------------------------------- *!*/

/*!***/
/* * ----------------------------------------*/
/* * animation text-focus-in*/
/* * ----------------------------------------*/
/* *!*/
/*@-webkit-keyframes text-focus-in {*/
/*    0% {*/
/*        -webkit-filter: blur(12px);*/
/*        filter: blur(12px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        -webkit-filter: blur(0px);*/
/*        filter: blur(0px);*/
/*        opacity: 1;*/
/*    }*/
/*}*/
/*@keyframes text-focus-in {*/
/*    0% {*/
/*        -webkit-filter: blur(12px);*/
/*        filter: blur(12px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        -webkit-filter: blur(0px);*/
/*        filter: blur(0px);*/
/*        opacity: 1;*/
/*    }*/
/*}*/

